import React, { useEffect } from "react"
import { graphql, Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { Fade } from "react-awesome-reveal"

import Layout from "../components/layout/Layout"
import Footer from "../components/footer/Footer"

const Articles = ({ data }) => {
  useEffect(() => {
    document.documentElement.style.setProperty("--header-bg", "#1B4555")
    document.documentElement.style.setProperty("--header-text", "#F8D990")
  }, [])

  const articleList = data.allCraftEntryInterface.nodes
  const page = data.craftBlogBlogEntry
  if (articleList.length === 0) {
    return (
      <Layout
        title={page.metaTitle || page.title}
        description={page.metaDescription}
      >
        <div className="px-ogs bg-teal pb-32">
          <h1 className="text-8xl lg:text-12xl py-10 text-warm-yellow font-fletcha">
            {page.title}
          </h1>
          <div className="pt-32 space-y-32 flex flex-col justify-between">
            <div className="text-warm-yellow text-2xl">
              Sorry, we don't have any articles at the moment. Please check back
              later.
            </div>
            <Fade triggerOnce duration={500}>
              <Link
                to="/"
                className="Link text-warm-yellow Link--alt Link--underline text-xl lg:text-3xl font-tomato tracking-tightest cursor-pointer"
              >
                Back to home
              </Link>
            </Fade>
          </div>
        </div>
        <Footer />
      </Layout>
    )
  }

  return (
    <Layout
      title={page.metaTitle || page.title}
      description={page.metaDescription}
    >
      <div className="px-ogs bg-teal pb-32">
        <h1 className="text-8xl lg:text-12xl py-10 text-warm-yellow font-fletcha">
          {page.title}
        </h1>
        <div className="grid md:grid-cols-2 xl:grid-cols-3 grid-divider gap-y-8 relative py-8 md:-mx-ogs">
          {articleList.map((article, index) => (
            <Link
              to={article.uri}
              key={index}
              className="md:border-l md:border-sand Link-group"
            >
              <div className="mt-8 md:px-ogs">
                {article.image && article.image.length > 0 && (
                  <div className="Link-group__image">
                    <div className="Link-group__image-overlay"></div>
                    <GatsbyImage
                      image={getImage(article.image[0].localFile)}
                      alt={article.image[0].title}
                    />
                  </div>
                )}
                <div className="text-warm-yellow rte mt-8">
                  <h2 className="text-6xl inline-block xl:text-6xlb Link-group__heading font-fletcha tracking-tightest leading-16 relative pb-1">
                    {article.title}
                  </h2>
                  <p className="italic">
                    Posted on:{" "}
                    {new Date(article.postDate).toLocaleDateString("en-GB", {
                      day: "numeric",
                      month: "long",
                      year: "numeric",
                    })}
                  </p>
                  <p className="font-tomato text-sm pb-12 mt-12">
                    {article.summary}
                  </p>
                </div>
              </div>
            </Link>
          ))}
        </div>
        <Fade triggerOnce duration={500}>
          <Link
            to="/"
            className="Link text-warm-yellow Link--alt Link--underline text-xl lg:text-3xl font-tomato tracking-tightest cursor-pointer"
          >
            Back to home
          </Link>
        </Fade>
      </div>
      <Footer />
    </Layout>
  )
}

export default Articles

export const articleQuery = graphql`
  query {
    craftBlogBlogEntry {
      metaTitle
      metaDescription
      title
    }

    allCraftEntryInterface(
      filter: { sectionHandle: { eq: "articles" } }
      sort: { fields: postDate, order: DESC }
    ) {
      nodes {
        ... on Craft_articles_articles_Entry {
          title
          postDate
          uri
          summary
          image {
            ... on Craft_images_Asset {
              id
              title
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    aspectRatio: 1
                    transformOptions: { fit: COVER, cropFocus: CENTER }
                  )
                }
              }
            }
          }
        }
      }
    }
  }
`
